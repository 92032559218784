<template>
<!-- 收付款账户 -->
  <div
    class="bg-white px-3 mt-5 list-inline"
    style="margin: -20px; margin-top: 1rem; margin-bottom: 0 !important"
  ><span class="list-inline-item">请选择账户：</span>
    <span class="list-inline-item">
       <!--banksclass 1-表示收付款账户  2表示股东往来款账户 3表示往来款项账户-->
      <sfbanks-select
        @select-change="sfbanksselect"
        placeholder="请选择账户"
        banksclass="1"
      ></sfbanks-select>
    </span>
    <span style="margin-left:50px;"><span class="list-inline-item">选择日期：</span> <el-date-picker
        v-model="datas.datestart"
          type="date"
              placeholder=""
              size="small"
              format="YYYY/M/D"
              value-format="YYYY-M-D"
              :editable="false"
              :clearable="false"
      /><span class="list-inline-item" style="margin-left:10px;">--</span> <el-date-picker
        v-model="datas.dateend"
          type="date"
              placeholder=""
              size="small"
              format="YYYY/M/D"
              value-format="YYYY-M-D"
              :editable="false"
              :clearable="false"
      /> <el-input v-model="searchaccount_name" placeholder="对方单位名称" style="width:150px;"  size="small"/>
      <el-button style="margin-left:10px;" size="small" @click="searchBut">查询</el-button></span>
    <span class="list-inline-item float-right mb-2"><el-button @click="toexcelfile">导出excel</el-button></span>
  </div>
  <el-table :data="tableData" border style="width: 100%" height="100%">
    <el-table-column prop="transaction_time" sortable label="时间" width="180" />
    <el-table-column prop="income"  label="收入" width="140"  :formatter="toThousands"/>
    <el-table-column prop="expenditure"  label="支出" width="140" :formatter="toThousands" />
    <el-table-column prop="balance" label="余额" width="140" :formatter="toThousands" />
    <el-table-column prop="detaied_text" label="摘要" width="180" />
     <el-table-column prop="account_name" label="对方单位名称" sortable width="200" />
    <el-table-column prop="bank_no" label="收款账号" width="200" />
    <el-table-column prop="bank_name" label="对方开户行名称" width="180" />
    <el-table-column prop="fk_request_id_no" label="对应付款申请单号" width="280" />
  </el-table>
</template>

<script>
import axios from "axios";
//import { ElMessageBox } from "element-plus";
export default {
  data() {
    return {
      //选择日期邦定值
      datas:{
        datestart:'2022-07-18',   //开始查找日期
        dateend:'2022-07-29'  //结束查找日期
      },
      searchaccount_name:'',  //需要搜索的账户名
      tableData:[],
      //选中的银行ID号
      banksid:"",
      //选中的银行账户名称
      account_name:""
    };
  },
  created(){
    this.showtime()
  },
  methods: {
    //单击查询按钮
    searchBut(){
      this.loadbanksinfo(this.banksid)
    },
    sfbanksselect(value) {
      console.log(value.id);
      this.banksid = value.id  //选中的银行ID
      this.account_name = value.account_name  //选中的银行账户名称
       this.loadbanksinfo(value.id)
    },
    //加载账户流水  参数banksid  为账户ID
    loadbanksinfo(banksid){
       axios
        .get("admin/SfBanksDetaied", {
          params: {
            project_id: window.sessionStorage.getItem("project_ids"),
            sf_banks_id: banksid,
            startdate:this.datas.datestart,
            enddate:this.datas.dateend,
            account_name:this.searchaccount_name,
          },
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log("******", response.data.data, "******");
          this.tableData = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },
    //格式化数字
    toThousands(row, column, cellValue) {
      return cellValue.toLocaleString("zh", {
        style: "currency",
        currency: "CNY",
      });
    },
    //导出excel文件
    toexcelfile(){
      axios
        .get("admin/Phpexcels", {
          params: {
            project_id: window.sessionStorage.getItem("project_ids"),
            sf_banks_id: this.banksid
          },
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
          responseType:'blob'  //改参数会以blob流格式返回
        })
        .then((response) => {
          let blob = new Blob([response.data])
          let url = URL.createObjectURL(blob)
          //下载该blob链接
          this.downloadFile(url,this.account_name+'.xlsx')
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
   },
    //下载EXCEL文件方法
    downloadFile(downUrl,fileName){
      const aLinkUrl =document.createElementNS('http://www.w3.org/1999/xhtml','a')
      aLinkUrl.href = downUrl
      aLinkUrl.download = fileName
      const clickAlink=(obj)=>{  //模拟点击
        const ev=document.createEvent('MouseEvents')
        ev.initMouseEvent('click',true,false,window,0,0,0,0,0,false,false,false,false,0,null)
        obj.dispatchEvent(ev)
      }
      clickAlink(aLinkUrl)
    },
    // 获取时间
    showtime() {
        let nowdate = new Date();
        let year = nowdate.getFullYear()
        let month = nowdate.getMonth() + 1
        let date = nowdate.getDate()
        this.datas.dateend = year + "-" + month + "-" + date;
        this.datas.datestart = year + "-" + month + "-1";
         
         console.log('this.datas.datestart:',this.datas.datestart)
    }
  },
};
</script>