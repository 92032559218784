<template>
  <!-- 表头 -->
  <table
    class="table text-center"
    style="
      margin: 0 0 0 0;
      width: 1098px;
      font-color: #fff;
      border: 0px solid #fff;
      margin-bottom: -2px;
    "
  >
    <thead>
      <tr style="border: 0px solid #fff">
        <th colspan="5" style="width: 100%; font-size: 25px">成本资金表</th>
      </tr>
      <tr style="background: #000080; border: 0px solid #fff; color: #fff">
        <th style="width: 378px; background: #000080; border: 1px solid #fff">
          科目
        </th>
        <th style="width: 178px; background: #000080; border: 1px solid #fff">
          A-预算值
        </th>
        <th style="width: 178px; background: #000080; border: 1px solid #fff">
          B-结算值
        </th>
        <th style="width: 178px; background: #000080; border: 1px solid #fff">
          C-已发生额
        </th>
        <th style="width: 178px; background: #000080; border: 1px solid #fff">
          D-未发生额(B-C)
        </th>
      </tr>
    </thead>
  </table>
  <!-- 收款 -->
  <el-table
    style="width: 1099px"
    :data="sytableData"
    row-key="id"
    border
    row-style='{background = "#fff0f5"}'
    :header-cell-style="{ background: '#000080', color: '#ffff' }"
  >
    <el-table-column label="一、收款" width="380">
      <template #default="scope">
        <span>{{ getscontractclass(scope.row.scontractclass) }}</span>
      </template>
    </el-table-column>
    <!-- 预算值 -->
    <el-table-column
      prop="budget"
      :label="toThousands('', '', sktotals.budget)"
      width="179"
      align="right"
      :formatter="toThousands"
    />
    <!-- 合同值 -->
    <el-table-column
      prop="contract_amount"
      :label="toThousands('', '', sktotals.contract_amount)"
      width="179"
      align="right"
      :formatter="toThousands"
    />
    <!-- 已发生额  -->
    <el-table-column
      prop="request_amout"
      :label="toThousands('', '', sktotals.request_amout)"
      width="180"
      align="right"
      :formatter="toThousands"
    />
    <!-- 未发生额 -->
    <el-table-column
      prop="difference"
      :label="toThousands('', '', sktotals.difference)"
      width="179"
      align="right"
      :formatter="toThousands"
    />
  </el-table>
  <!--股东账户-->
  <el-table
    style="width: 1100px"
    :data="wltableData"
    row-key="id"
    border
    :row-style="TableRowStyle"
    :header-cell-style="{ background: '#000080', color: '#ffff' }"
  >
    <el-table-column prop="account_name" label="股东往来账户" width="380" />
    <el-table-column
      width="179"
      align="right"
      :label="toThousands('', '', gdtotals.contarctamout)"
    />
    <el-table-column
      width="179"
      align="right"
      :label="toThousands('', '', gdtotals.contarctamout)"
    />
    <el-table-column
      prop="balance"
      :label="toThousands('', '', gdtotals.requestamout)"
      width="180"
      align="right"
      :formatter="toThousands"
    />
    <el-table-column
      width="180"
      align="right"
      :label="toThousands('', '', gdtotals.difference)"
    />
  </el-table>
  <!-- 成本分类 -->
  <el-table
    style="width: 1100px"
    :data="options"
    row-key="id"
    border
    :tree-props="{ children: 'child', hasChildren: 'hasChildren' }"
    :row-style="TableRowStyle"
    :header-cell-style="{ background: '#000080', color: '#ffff' }"
  >
    <el-table-column prop="name" label="二、付款" width="380" />
    <el-table-column
      prop="budget"
      width="180"
      align="right"
      :label="toThousands('', '', fktotals.budget)"
      :formatter="toThousands"
    />
    <el-table-column
      prop="contarctamout"
      :label="toThousands('', '', fktotals.contarctamout)"
      width="179"
      align="right"
      :formatter="toThousands"
    />
    <el-table-column
      prop="requestamout"
      :label="toThousands('', '', fktotals.requestamout)"
      width="179"
      align="right"
      :formatter="toThousands"
    />
    <el-table-column
      prop="difference"
      :label="toThousands('', '', fktotals.difference)"
      width="180"
      align="right"
      :formatter="toThousands"
    />
  </el-table>
  <!-- 表尾 -->
  <table
    class="table text-center"
    style="
      margin: 0 0 0 0;
      width: 1101px;
      font-color: #fff;
      border: 0px solid #fff;
      margin-bottom: -2px;
    "
  >
    <thead>
      <tr
        style="
          background: #000080;
          border: 0px solid #fff;
          color: #fff;
          font-size: 14px;
        "
      >
        <th
          class="text-left"
          style="width: 374px; background: #000080; border: 1px solid #fff"
        >
          三、利润
        </th>
        <th
          class="text-right"
          style="width: 177px; background: #000080; border: 1px solid #fff"
        >
          {{ toThousands("", "", profits.budget) }}
        </th>
        <th
          class="text-right"
          style="width: 176px; background: #000080; border: 1px solid #fff"
        >
          {{ toThousands("", "", profits.contarctamout) }}
        </th>
        <th
          class="text-right"
          style="width: 176px; background: #000080; border: 1px solid #fff"
        >
          {{ toThousands("", "", profits.requestamout) }}
        </th>
        <th
          class="text-right"
          style="width: 179px; background: #000080; border: 1px solid #fff"
        >
          {{ toThousands("", "", profits.difference) }}
        </th>
      </tr>
    </thead>
  </table>
</template>

<script>
//import axios from "axios";
export default {
  data() {
    return {
      options: [], //成本支出汇总表
      sytableData: [], //收款汇总表
      wltableData: [], //往来汇总表

      //收款合计
      sktotals: {
        budget: 0,
        contract_amount: 0,
        request_amout: 0,
        difference: 0,
      },
      //付款合计
      fktotals: {
        budget: 0,
        contarctamout: 0,
        requestamout: 0,
        difference: 0,
      },
      //股东款合计
      gdtotals: {
        contarctamout: 0,
        requestamout: 0,
        difference: 0,
      },
      //计算利润
      profits: {
        budget: 0,
        contarctamout: 0,
        requestamout: 0,
        difference: 0,
      }, // 已发生额
    };
  },
  created() {
    //收款明细及合计
    this.IncomeRecordt();
  },
  methods: {
    //返回合同类型
    getscontractclass(val) {
      if (val === "1") {
        return "主合同";
      }
      if (val === "2") {
        return "变更项";
      }
      if (val === "3") {
        return "核减项";
      }
    },
    //付款明细
    fkrequest() {
      this.axios
        .get("admin/Zjjhlist/zjjhzcfzb", {
          params: {
            project_id: window.sessionStorage.getItem("project_ids"),
          },
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log("汇总付款明细", response.data.data);
          this.options = response.data.data;
          //汇总付款明细
          this.options.forEach((element) => {
            this.fktotals.contarctamout =
              parseFloat(element.contarctamout) +
              parseFloat(this.fktotals.contarctamout);
            this.fktotals.requestamout =
              parseFloat(element.requestamout) +
              parseFloat(this.fktotals.requestamout);
            this.fktotals.difference =
              parseFloat(element.difference) +
              parseFloat(this.fktotals.difference);
            if (element.budget) {
              this.fktotals.budget =
                parseFloat(element.budget) + parseFloat(this.fktotals.budget);
            }
          });
          //计算利润 发生额
          this.profits.requestamout =
            this.sktotals.request_amout +
            this.gdtotals.requestamout -
            this.fktotals.requestamout;
          //计算利润 合同额
          this.profits.contarctamout =
            this.sktotals.contract_amount - this.fktotals.contarctamout;
          //计算利润 差额
          this.profits.difference =
            this.sktotals.difference - this.fktotals.difference;
          //计算利润 预算值
          this.profits.budget = this.sktotals.budget - this.fktotals.budget;
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },
    //股东往来明细
    SfBanksWl() {
      this.axios
        .get("admin/SfBanks/indexsum", {
          params: {
            project_id: window.sessionStorage.getItem("project_ids"),
          },
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.wltableData = response.data.data;
          console.log("汇总股东往来明细", response.data.data);
          //汇总股东往来明细

          this.wltableData.forEach((element) => {
            this.gdtotals.requestamout =
              parseFloat(element.balance) +
              parseFloat(this.gdtotals.requestamout);
          });

          //付款明细及合计
          this.fkrequest();
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },
    //收款明细合计
    IncomeRecordt() {
      this.axios
        .get("admin/IncomeRecord/indexsum", {
          params: {
            project_id: window.sessionStorage.getItem("project_ids"),
          },
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.sytableData = response.data.data;
          console.log("收款明细合计", this.sytableData);
          //收款明细合计
          this.sytableData.forEach((element) => {
            this.sktotals.contract_amount =
              parseFloat(element.contract_amount) +
              parseFloat(this.sktotals.contract_amount);
            this.sktotals.request_amout =
              parseFloat(element.request_amout) +
              parseFloat(this.sktotals.request_amout);
            this.sktotals.difference =
              parseFloat(element.difference) +
              parseFloat(this.sktotals.difference);
            this.sktotals.budget =
              parseFloat(element.budget) + parseFloat(this.sktotals.budget);
          });

          //股东往来明细
          this.SfBanksWl();
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },

    //行样式方法
    TableRowStyle(row) {
      let rowBackground = {};
      if (row.row.status === 0) {
        rowBackground.background = "#000080";
        rowBackground.color = "#ffff";
        rowBackground.fontWeight = "bold";
        return rowBackground;
      } else if (row.row.status === 1) {
        rowBackground.background = "#00bbff";
        rowBackground.color = "#ffff";
        rowBackground.fontWeight = "bold";
        return rowBackground;
      } else if (row.row.status === 2) {
        rowBackground.background = "#77ddff";
        rowBackground.fontWeight = "bold";
        return rowBackground;
      } else if (row.row.status === 3) {
        rowBackground.background = "#cceeff";
        return rowBackground;
      } else if (row.row.status === 4) {
        rowBackground.background = "#fff0f5";
        return rowBackground;
      }
      return "";
    },
    //格式化数字
    toThousands(row, column, cellValue) {
      // 如果cellValue 为null 则不执行
      if (cellValue) {
        return cellValue.toLocaleString("zh", {
          style: "currency",
          currency: "CNY",
        });
      }
    },
  },
};
</script>

<style>
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
.el-tree-node__content {
  height: 40px;
}
.el-table {
  /* 鼠标经过row显示的背景色 */
  --el-table-row-hover-background-color: transparent;
}
</style>
