<template>
  <div
    class="bg-white px-3 mt-5"
    style="margin: -20px; margin-top: 1rem; margin-bottom: 0 !important"
  >
  <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
    <el-tab-pane label="成本资金表" name="first">
      <statement-sheet></statement-sheet>
    </el-tab-pane>
    <el-tab-pane label="工程款往来表" name="second">
      <div  style="margin-left: 10px; margin-top: 1rem; height:650px;"><gskwl-sheet></gskwl-sheet></div></el-tab-pane>
    <el-tab-pane label="税金测算表" name="third">税金测算表</el-tab-pane>
  </el-tabs>
  </div>
</template>
  

<script>
import statementVue from "../../components/statement/balancesheet.vue";
import gskwlVue from "../banks/sfbanks/list.vue"
export default {
  data() {
    return {
      activeName:"first",

    };
  },

  methods: {
    handleClick(){
    }
  },
  components: {
    "statement-sheet": statementVue,
    "gskwl-sheet":gskwlVue
  },
};
</script>


<style>

</style>